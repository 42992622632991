<template>
  <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog')" width="750">
    <v-card>
      <v-card-title class="title-modal">
        <span class="headline">Asignar a ola de preparacion</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-combobox v-model="item.wave" name="wave" item-title="id" item-value="id" :items="waves" label="Olas*"
            autocomplete="off" :rules="[rules.requiredComboBox]" clearable></v-combobox>

        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
        <v-btn color="green" text @click="assign()" :loading="loading"> Asignar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { waveService } from "@/libs/ws/waveService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() { },
  mixins: [ValidMixin],
  components: {},
  props: {
    dialog: Boolean,
    updateDialog: Function,
    assignPreparation: Function,
    loading: Boolean
  },
  data: () => ({
    item: {
      wave: null
    },
    defaultItem: null,
    waves: [],
  }),
  watch: {

  },
  mounted() {
    this.loadWaves();
  },
  methods: {
    loadWaves() {
      const today = new Date();

      const dateTo = today.toISOString().split('T')[0];

      const dateFrom = new Date(today);
      dateFrom.setDate(today.getDate() - 30);
      const formattedDateFrom = dateFrom.toISOString().split('T')[0];

      const params = {
        date_from: formattedDateFrom,
        date_to: dateTo
      };

      waveService.filter(params).then((response) => {
        this.waves = response.result.list;
      });
    },
    async assign() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$emit("assignPreparation", this.item.wave);
      }
    },
  },
};
</script>