<template>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog')" width="750">
        <v-card>
            <v-card-title class="title-modal">
                <span class="headline">{{ title }}</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form">
                    <v-combobox v-model="item.dock" name="name" item-title="name" item-value="id" :items="muelles"
                        label="Muelle*" autocomplete="off" :rules="[rules.requiredComboBox]" clearable></v-combobox>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
                <v-btn color="green" text @click="assign()" :loading="loading"> {{button_accept}} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { muelleService } from "@/libs/ws/muelleService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
    created() { },
    mixins: [ValidMixin],
    components: {},
    props: {
        dialog: Boolean,
        updateDialog: Function,
        assignMuelle: Function,
        loading: Boolean,
        title: String,
        button_accept: String
    },
    data: () => ({
        item: {
            dock: null
        },
        defaultItem: null,
        muelles: [],
    }),
    watch: {

    },
    mounted() {
        this.loadMuelles();
    },
    methods: {
        loadMuelles() {
            this.loading_muelles = true;
            muelleService
                .filter()
                .then((response) => {
                    this.muelles = response.result.list;
                })
                .catch((e) => {
                    console.log("service.load error");
                    console.log(e);
                })
                .finally(() => {
                    this.loading_muelles = false;
                });
        },
        async assign() {
            const { valid } = await this.$refs.form.validate();
            if (valid) {
                this.$emit("assignMuelle", this.item.dock);
            }
        },
    },
};
</script>