<template>
  <div>
    <v-layout class="pull-left">
      <v-dialog
        :modelValue="show"
        max-width="450px"
        @keydown.esc="$emit('close')"
        @click:outside="$emit('close')"
      >
        <v-card>
          <v-form v-on:submit.prevent="print">
            <v-card-title style="margin-bottom: 0px !important">
              <span class="headline">Imprimir</span>
            </v-card-title>

            <v-card-text>
              <p class="fs-16 mt-4">Seleccione el formato de impresión</p>
              <v-radio-group v-model="format_page">
                <v-radio
                  style="margin-top: 10px"
                  label="Formato ZPL"
                  value="zpl"
                ></v-radio>
                <v-radio
                  style="margin-top: 10px"
                  label="Formato PDF"
                  value="pdf"
                ></v-radio>
              </v-radio-group>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="$emit('close')">Cancelar</v-btn>
              <v-btn
                color="success"
                text
                type="submit"
                :loading="loading_imprimir"
                >Imprimir</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { ubicacionService } from "@/libs/ws/ubicacionService";
export default {
  props: {
    show: Boolean,
    close: Function,
    selected: Array,
    list: Array,
    cleanSelected: Function,
  },
  name: "DialogPrint",
  mixins: [],
  created() {},
  data() {
    return {
      loading_imprimir: false,
      format_page: "zpl",
    };
  },
  mounted() {},
  computed: {},
  methods: {
    print() {
      //dados los id que estan en selected busco en list el codigo de cada uno
      let codigos = this.selected.map((id) => {
        let item = this.list.find((item) => item.id == id);
        return item.id;
      });
      var params = {
        codes: codigos,
      };
      this.loading_imprimir = true;

      ubicacionService.print(params, this.format_page).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response.data));
          if (!result.status) {
            this.toast.error(result.msg);
          }
        } catch (e) {
          let blob;
          let link;
          switch (response.headers["content-type"]) {
            case "application/pdf":
            case "text/html; charset=UTF-8":
              blob = new Blob([response.data], {
                type: "application/pdf",
              });
              link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_blank";
              //link.download = code+'.pdf'
              link.click();
              break;
            case "application/zip":
              blob = new Blob([response.data], {
                type: "application/zip",
              });
              link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_blank";
              link.download = "Ubicaciones.zip";
              //link.download = code+'.pdf'
              link.click();
              break;
            case "text/plain; charset=UTF-8":
              blob = new Blob([response.data], {
                type: "text/plain",
              });
              link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_blank";
              link.download = codigos[0] + ".txt";
              //link.download = code+'.pdf'
              link.click();
              break;
          }
        }
        this.loading_imprimir = false;
        this.$emit("close");
        this.$emit("cleanSelected");
      });
    },
    arrayBufferToString(buffer) {
      var arr = new Uint8Array(buffer);
      var str = String.fromCharCode.apply(String, arr);
      if (/[\u0080-\uffff]/.test(str)) {
        throw new Error(
          "this string seems to contain (still encoded) multibytes"
        );
      }
      return str;
    },
  },
};
</script>
