<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="closeDialog()" max-width="1000px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-row align="center" justify="center" class="mb-4 mt-4">
              <v-col md="10" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                <a-steps :current="step">
                  <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
              </v-col>
            </v-row>

            <div class="steps-content">
              <!--step 0-->
              <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition">
                <v-col md="10" class="mt-4 pr-0 pl-0">
                  <v-form ref="form_step1">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0"><b>Datos Generales</b></p>
                    </div>
                    <p>Complete los datos generales del ASN</p>
                    <v-row>
                      <v-col md="6">
                        <v-text-field
                          type="date"
                          v-model="item.estimated_arrival_date"
                          name="estimated_arrival_date"
                          label="Fecha estimada de llegada*"
                          :disabled="disabled"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.document_type"
                          name="document_type"
                          item-title="name"
                          item-value="id"
                          :items="documents_types"
                          label="Tipo de Documento*"
                          autocomplete="off"
                          :disabled="disabled"
                          :rules="[rules.requiredComboBox]"
                          clearable
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6" v-if="user_type != userTypeConstant.CLIENT">
                        <v-combobox
                          v-model="item.client"
                          name="client"
                          item-title="name"
                          item-value="id"
                          :items="clients"
                          label="Cliente*"
                          autocomplete="off"
                          :disabled="disabled"
                          :rules="[rules.requiredComboBox]"
                          clearable
                        ></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox
                          v-model="item.almacen"
                          name="rol"
                          item-title="name"
                          item-value="id"
                          :items="almacenes"
                          label="Almacen*"
                          autocomplete="off"
                          :disabled="disabled"
                          :rules="[rules.requiredComboBox]"
                          clearable
                        ></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-text-field label="Referencia Externa" v-model="item.external_reference" :disabled="disabled"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-btn class="float-right btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                </v-col>
              </v-row>

              <!--step 1-->
              <v-row align="start" justify="center" v-if="step == 1" transition="fade-transition">
                <v-col md="10" class="mt-4 pr-0 pl-0">
                  <div class="px-2 py-1 mb-3 bg-subtitle-section">
                    <p class="text-subtitle-1 mb-0"><b>Detalle ASN</b></p>
                  </div>
                  <p>Ingrese el detalle del ASN</p>

                  <TablePresentations :asn="item" :open="dialog" :validator="$validator" :isViewModal="disabled" @addProduct="addProduct" @removeProduct="removeProduct" />

                  <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                  <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDialog()">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled && step == 1">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { almacenService } from "@/libs/ws/almacenService";
import { clientService } from "@/libs/ws/clientService";
import { documentsTypeService } from "@/libs/ws/documentsTypeService";
import UserTypeConstant from "@/constants/user_type";

import ValidMixin from "@/mixins/ValidMixin.vue";
import TablePresentations from "./TablePresentations.vue";

export default {
  components: { TablePresentations },
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      clients: [],
      almacenes: [],
      documents_types: [],
      loading: false,
      step: 0,
      steps: [
        {
          title: "Datos Generales",
          content: "First-content",
        },
        {
          title: "Detalle ASN",
          content: "Second-content",
        },
      ],
      item: {
        //cabecera
        id: "",
        document_type: null,
        client: null,
        almacen: null,
        external_reference: "",
        estimated_arrival_date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),

        // detalle
        asn_details: [],
      },
      defaultItem: null,
      user_type: null,
      userTypeConstant: UserTypeConstant,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadCombobox();
        this.disabled = this.formBtn == "";
        this.step = 0;
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");

    this.loadCombobox();
  },
  methods: {
    closeDialog() {
      this.$emit("updateDialog", false);
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
    },
    prev() {
      this.step--;
    },
    async next() {
      //this.step++;

      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },
    loadCombobox() {
      this.loadAlmacenes();
      this.loadClientes();
      this.loadDocumentTypes();
      this.defaultItem = JSON.parse(JSON.stringify(this.item));
    },
    comboCategoryChange(event) {
      this.loadSubcategorias();
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDocumentTypes() {
      documentsTypeService
        .filter({ order_type: "IN" })
        .then((response) => {
          this.documents_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    addProduct(item, index = -1) {
      if (index == -1) this.item.asn_details.push(item);
      else this.item.asn_details[index] = item;
    },
    removeProduct(index) {
      this.item.asn_details.splice(index, 1);
    },
    validateForm(){
      if(this.item.asn_details.length == 0){
        this.toast.error("Debe ingresar al menos un detalle de ASN");        
        return false;
      }     
      return true;
    },
    createItem() {
      if (this.loading) return;

      if (this.validateForm()) {
        this.loading = true;

        var data = JSON.parse(JSON.stringify(this.item));
        data.asn_details_arr = data.asn_details;
        delete data.asn_details;
        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.closeDialog();
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.closeDialog();
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
