<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :items="task.assigned_locations"
      :search="search"
      :loading="loading_lpns"
      loading-text="Cargando..."
      class="expandable-rows"
      :items-per-page="10"
      hide-default-footer
      dense
      no-data-text="No se encontraron LPNs"
      v-model="assigned_locations_selected"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{ task.origin_lpn.code }}</td>
          <td>{{ item.presentation_atributes.product_presentation.product.client.name }}</td>
          <td>{{ item.presentation_atributes.product_presentation.product.name }}</td>
          <td>{{ item.presentation_atributes.expiration_date != null ? item.presentation_atributes.expiration_date : "-" }}</td>
          <td>{{ item.presentation_atributes.lote != null ? item.presentation_atributes.lote : "-" }}</td>
          <td>{{ item.presentation_atributes.serie != null ? item.presentation_atributes.serie : "-" }}</td>
          <td>{{ item.presentation_atributes.product_status != null ? item.presentation_atributes.product_status.name : "-" }}</td>
          <td>{{ item.available_stock }}</td>
          <!-- Columna dinámica con validación -->
          <td v-if="showTransferColumn">
            <v-text-field
              v-model="item.transfer_quantity"
              dense
              outlined
              placeholder="0"
              :error-messages="errors[index]"
            />
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-btn class="float-right btn-second mt-5" @click="nextStep()">Siguiente</v-btn>
  </div>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() {
    if (this.showTransferColumn) {
      this.task.assigned_locations.forEach((item) => {
        if (!Object.hasOwn(item, "transfer_quantity")) {
          item.transfer_quantity = 0; 
        }
      });
    }
  },

  mixins: [ValidMixin],
  props: {
    form_ref: Object,
    task: Object,
    addPropertyToTask: Function,
    setStep: Function,
  },
  data: () => ({
    headers: [
      { title: "LPN", key: "lpn" },
      { title: "Cliente", key: "client" },
      { title: "Producto", key: "product" },
      { title: "Vencimiento", key: "expiration_date" },
      { title: "Lote", key: "lote" },
      { title: "Serie", key: "serie" },
      { title: "Estado", key: "product_status" },
      { title: "Cantidad disponible", key: "available_stock" },
    ],
    errors: [], 
  }),
  computed: {
    computedHeaders() {
      if (this.showTransferColumn) {
        return [...this.headers, { title: "Cantidad a transferir", key: "transfer_quantity" }];
      }
      return this.headers;
    },
    showTransferColumn() {
      return this.task.movement_type === "product";
    },
  },
  methods: {
    nextStep() {
      if(this.task.movement_type === 'product') { 
        const hasValidTransfer = this.task.assigned_locations.some((item) => {
          return parseInt(item.transfer_quantity) > 0;
        });
  
        if (!hasValidTransfer) {
          this.toast.error("Debe transferir al menos una cantidad mayor a 0.");
          return;
        }
      }

      this.$emit("setStep", 4);
    },
  },
};
</script>
