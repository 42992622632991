<template>
  <div>
    <v-card class="mb-4">
      <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
        <div class="col-md-12">
          <div class="col-md-12" style="background: white">
            <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
            <v-divider class="mt-0"></v-divider>
            <div class="row pt-2">
              <div class="col">
                <v-text-field v-model="code_filter" density="comfortable" name="code" label="LPN"
                  :disabled="getDisabled()"></v-text-field>
              </div>

              <div class="col">
                <v-combobox v-model="zona_filter" density="comfortable" name="zona" item-title="label" item-value="id"
                  :items="zonas" label="Zona" autocomplete="off" :disabled="getDisabled()" @update:modelValue="() => {
                      loadUbicaciones();
                      loadProducts(); //Productos que estan en la zona
                    }
                    " clearable :loading="loading_zonas"></v-combobox>
              </div>

              <div class="col">
                <v-combobox v-model="ubicacion_filter" density="comfortable" name="ubicacion" item-title="name"
                  item-value="id" :items="ubicaciones" label="Ubicacion" autocomplete="off" clearable
                  :loading="loading_ubicaciones" :disabled="zona_filter == null || getDisabled()" @update:modelValue="() => {
                      loadProducts(); //Productos que estan en la ubicacion
                    }
                    "></v-combobox>
              </div>

              <div class="col-md-3">
                <v-combobox v-model="product_filter" density="comfortable" name="product_selected" item-title="name"
                  item-value="id" :items="products" label="Producto" autocomplete="off" @update:modelValue="() => {
                      loadZonas(); //Zonas en las que estan los productos
                    }
                    " :loading="loading_product" clearable :disabled="getDisabled()"></v-combobox>
              </div>

              <div class="col-md-2 mt-2" style="display: flex; justify-content: end">
                <v-btn color="info" class="" @click="filterLPNs" prepend-icon="mdi-magnify"
                  :disabled="getDisabled()">Buscar</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <v-data-table :headers="headers" :items="lpns" :search="search" :loading="loading_lpns" loading-text="Cargando..."
      class="expandable-rows" :items-per-page="10" hide-default-footer dense no-data-text="No se encontraron LPNs"
      v-model="assigned_locations_selected">
      <template v-slot:item="{ item, isSelected, toggleSelect}">
        <tr>
          <td class="pl-2">
            <v-checkbox style="margin: 0px; padding: 0px" hide-details :model-value="isSelected({ value: item })"
            @update:modelValue="toggleSelect({ value: item })" :disabled="getDisabled()" />
          </td>
          <td>{{ item.lpn.code }}</td>
          <td>{{ item.ubicacion.zona.label }}</td>
          <td>{{ item.ubicacion.code }}</td>
          <td>{{ item.lpn.state.name }}</td>

          <td class="expand-icon"  @click="toggleExpand(item.assigned_locations[0].id)">
            <v-icon>{{ expandedRows.includes(item.assigned_locations[0].id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </td>
        </tr>
        <tr v-if="expandedRows.includes(item.assigned_locations[0].id)">
          <td colspan="6" class="pl-0 pr-0">
            <v-data-table class="custom-subtable" :headers="assignedLocationHeaders" :items="item.assigned_locations"
              hide-default-footer disable-pagination style="background-color: #ccc">
              <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.presentation_atributes.product_presentation.product.client.name }}</td>
          <td>{{ item.presentation_atributes.product_presentation.product.name }}</td>
          <td>{{ item.presentation_atributes.expiration_date != null ? item.presentation_atributes.expiration_date : "-"}}</td>
          <td>{{ item.presentation_atributes.lote != null ? item.presentation_atributes.lote : "-" }}</td>
          <td>{{ item.presentation_atributes.serie != null ? item.presentation_atributes.serie : "-"  }}</td>
          <td>{{ item.presentation_atributes.product_status != null ? item.presentation_atributes.product_status.name : "-" }}</td>  
        </tr>
      </template>
    </v-data-table>
    </td>
    </tr>
</template>
</v-data-table>

<v-btn class="float-right btn-second mt-5" @click="nextStep()">Siguiente</v-btn>
</div>
</template>

<script>
import Constant from "@/constants/sections";
import { lpnService } from "@/libs/ws/lpnService";
import { productoService } from "@/libs/ws/productoService";
import { zonaService } from "@/libs/ws/zonaService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() { },
  mixins: [ValidMixin],
  components: {},
  props: {
    form_ref: Object,
    task: Object,
    filters: Object,

    setStep: Function,
    addPropertyToTask: Function,
    disabled: Boolean,
    handleListDetailOrigin: Function
  },
  data: () => ({
    //Filters
    code_filter: null,
    product_filter: null,
    zona_filter: null,
    ubicacion_filter: null,

    //Combos
    assigned_locations_selected: [],
    lpns: [],
    ubicaciones: [],
    zonas: [],
    products: [],
    presentation_products: [],
    
    //Loadings
    loading_ubicaciones: false,
    loading_ubicacion: false,
    loading_product: false,
    loading_product_presentation: false,
    loading_zonas: false,
    loading_lpns: false,

    headers: [
      {},
      { title: "LPN", key: "lpn.code" },
      { title: "Zona", key: "zona" },
      { title: "Ubicacion", key: "ubicacion" },
      { title: "Estado", key: "state.name" },
      {}
    ],
    assignedLocationHeaders: [
      {title: "Cliente", key: 'client.name'},
      { title: "Producto", key: "product.name" },
      {title: "Vencimiento", key: "expiration_date"},
      { title: "Lote", key: "lote" },
      { title: "Serie", key: "serie" },      
      {title: "Estado", key: "product_status"}
    ],
    expandedRows: [],
  }),
  watch: {
    filters: {
      handler: function (val) {
        this.code_filter = val.code;
        this.product_filter = val.product;
        this.zona_filter = val.zona;
        this.ubicacion_filter = val.ubicacion;
        this.filterLPNs();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboBox();

    //Set filters
    this.code_filter = this.filters.origen.code;
    this.product_filter = this.filters.origen.product;
    this.zona_filter = this.filters.origen.zona;
    this.ubicacion_filter = this.filters.origen.ubicacion;
    this.filterLPNs();
  },
  methods: {
    toggleExpand(id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter((rowId) => rowId !== id);
      } else {
        this.expandedRows.push(id);
      }
    },
    getDisabled() {
      return this.disabled || this.task.id > 0;
    },
    async nextStep() {
      const { valid } = await this.form_ref.validate();
      if (!valid) {
        this.toast.error("Complete los campos requeridos");
        return;
      }

      //Solo se puede seleccionar 1 LPN, osea el conjunto de AssignedLocations debe pertenecer a un solo LPN
      let lpns = [];
      this.assigned_locations_selected.forEach((x) => {
        if (!lpns.includes(x.lpn.code)) lpns.push(x.lpn.code);
      });

      if (lpns.length != 1) {
        this.toast.error("Debe seleccionar solo 1 LPN");
        return;
      }

      const lpn = this.assigned_locations_selected[0].lpn;
      if (!this.getDisabled()) this.$emit("addPropertyToTask", "origin_lpn", lpn); //Origen
      if (this.task.movement_type == "lpn" && !this.getDisabled()) this.$emit("addPropertyToTask", "destination_lpn", lpn);

      const ubicacion = this.assigned_locations_selected[0].ubicacion;
      if (!this.getDisabled()) this.$emit("addPropertyToTask", "origin_ubicacion", ubicacion);
      const zona = this.assigned_locations_selected[0].ubicacion.zona;
      
      this.$emit("addPropertyToTask", "assigned_locations", this.assigned_locations_selected[0].assigned_locations);
    
      let product, presentation_atributes, stock;
      if (this.assigned_locations_selected[0].assigned_locations.length == 1) {
        product = this.assigned_locations_selected[0].assigned_locations[0].presentation_atributes.product_presentation.product;
        presentation_atributes = this.assigned_locations_selected[0].assigned_locations[0].presentation_atributes;
        stock = this.assigned_locations_selected[0].assigned_locations[0].available_stock;

        if (!this.getDisabled()) {
          this.$emit("addPropertyToTask", "presentation_atributes", presentation_atributes);
          this.$emit("addPropertyToTask", "presentation_type", presentation_atributes.product_presentation.presentation_type);
          this.$emit("addPropertyToTask", "amount", stock);
          this.$emit("addPropertyToTask", "product", product); //Solo informativo
        }
      }

      this.$emit("addPropertyToFilters", "origen", {
        code: lpn.code,
        product: product,
        zona: zona,
        ubicacion: ubicacion,
      });

      this.$emit("setStep", 3);
    },
    loadComboBox() {
      this.loadZonas();
      this.loadProducts();
    },
    loadProducts() {
      this.loading_product = true;
      const params = {
        zona: this.zona_filter,
        ubicacoin: this.ubicacion_filter,
        almacen: this.task.almacen,
      };
      productoService
        .filter(params)
        .then((response) => {
          if (response.status === true) {
            if (response.result.list.length == 0) {
              this.toast.error("No se encontraron productos");
              this.product__selected = null;
            }
            this.products = response.result.list;
          } else {
            this.products = [];
            this.toast.error(response.msg);
          }
        })
        .finally(() => {
          this.loading_product = false;
        });
    },
    loadZonas() {
      this.loading_zonas = true;
      const params = {
        almacen: this.task.almacen,
      };
      zonaService
        .filter(params)
        .then((response) => {
          if (response.status === true) {
            this.zonas = response.result.list;
          } else {
            this.zonas = [];
            this.toast.error(response.msg);
          }
        })
        .finally(() => {
          this.loading_zonas = false;
        });
    },

    filterLPNs() {
      //Son AssignedLocations
      this.loading_lpns = true;
      const param = {
        product: this.product_filter,
        zona: this.zona_filter,
        ubicacion: this.ubicacion_filter,
        lpn_code: this.code_filter,
        almacen: this.task.almacen,
        grouped_by: "lpn_label"
      };

      lpnService
        .filter_assigned_locations(param)
        .then((response) => {
          if (response.status === true) {
            if (response.result.total == 0) {
              this.lpns = [];
              this.toast.error("No se encontraron LPNs");
            } else {
              this.lpns = response.result.list;

              if (this.task.origin_lpn != null) {
                this.assigned_locations_selected = this.lpns.filter((x) => x.lpn.code == this.task.origin_lpn.code);
              }
            }
          } else {
            this.toast.error(response.msg);
          }
        })
        .finally(() => {
          this.loading_lpns = false;
        });
    },
    async addLPN() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$emit("addNewLPN", this.item);
      }
    },
    loadUbicacion() {
      this.loading_ubicacion = true;
      const params = {
        lpn: this.task.origin_lpn
      };
      lpnService
        .scan(params)
        .then((response) => {
          if (response.status === false) {
            this.toast.error(response.msg);
            return;
          } else if (response.result == null) {
            this.toast.error("No se encontró la ubicación de origen del LPN");
            return;
          } else {
            this.origin_ubicacion = response.result.ubicacion;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadUbicaciones() {
      this.loading_ubicaciones = true;
      zonaService
        .get_ubicaciones({
          zona: this.zona_filter,
        })
        .then((response) => {
          this.ubicaciones = response.result;
        })
        .finally(() => {
          this.loading_ubicaciones = false;
        });
    },
  },
};
</script>
