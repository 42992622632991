function formatPrice(price) {  
  price = parseFloat(price).toFixed(2);
  return "$"+(new Intl.NumberFormat('es-AR', {})).format(price)
}

function formatPriceNumber(price) {  
  return parseFloat(parseFloat(price).toFixed(2));  
}

function formatDateString(date_string) {
  //Format "yyyy-mm-dd" to "dd/mm/yyyy"
  if(date_string === null) return "-";
  const date = new Date(date_string);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;  
}

function formatDate(dateString) {
  //Format dd/mm/yyyy to yyyy-mm-dd
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Asegura 2 dígitos
  const day = String(date.getDate()).padStart(2, '0'); // Asegura 2 dígitos
  return `${year}-${month}-${day}`;
}

export const formatUtils = {
  formatPrice,  
  formatPriceNumber,
  formatDateString,
  formatDate
};
