<template>
  <div>
    <v-layout class="pull-left">
      <v-dialog :modelValue="show" max-width="450px" @keydown.esc="$emit('close')" @click:outside="$emit('close')">
        <v-card>
          <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="print">

            <v-card-title style="margin-bottom: 0px !important">
              <span class="headline">Imprimir</span>
            </v-card-title>

            <v-card-text>
              <v-combobox v-model="item.presentation_type" name="presentation_type" item-title="name" item-value="id"
                :items="presentation_types" label="Tipo de presentacion*" autocomplete="off"
                :rules="[rules.requiredComboBox]" class="mt-4"></v-combobox>

              <v-text-field label="Cantidad*" name="amount" v-model="item.amount"
                :rules="[rules.required, rules.max100Amount]" :disabled="disabled"></v-text-field>

              
              <v-combobox v-model="item.measure" name="measure" item-title="name" item-value="id"
                :items="measures" label="Medidas de papel*" autocomplete="off"
                :rules="[rules.requiredComboBox]"></v-combobox>

              <p class="fs-16 ">Seleccione el formato de impresión</p>
              <v-radio-group v-model="format_page">
                <v-radio style="margin-top: 10px" label="Formato ZPL" value="zpl"></v-radio>
                <v-radio style="margin-top: 10px" label="Formato PDF" value="pdf"></v-radio>
              </v-radio-group>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="$emit('close')">Cancelar</v-btn>
              <v-btn color="success" text type="submit" :loading="loading_imprimir">Imprimir</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import ValidMixin from "@/mixins/ValidMixin.vue";
import { productoService } from "@/libs/ws/productoService";
import { generalService } from "@/libs/ws/generalService";

export default {
  props: {
    show: Boolean,
    close: Function,
    selected: Array,
    list: Array,
    cleanSelected: Function,
    presentations: Array
  },
  name: "DialogPrint",
  mixins: [ValidMixin],
  created() { },
  data() {
    return {
      loading_imprimir: false,
      format_page: "zpl",
      item: {
        amount: 0,
        measure: null,
        presentation_type: null
      },
      defaultItem:  {
        amount: 0,
        measure: null,
        presentation_type: null
      },
      presentation_types: [],
      measures: [{id: 1, name: "10x15"}, {id:2, name: "10x5"}]
    };
  },
 
  watch: {
    show() {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.presentation_types = this.presentations.map((presentation) => ({
        id: presentation.id,
        name: presentation.presentation_type.name + " (" + presentation.barcode + ")"
      }));
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    print() {
      //dados los id que estan en selected busco en list el codigo de cada uno
      if (this.loading) return;

      if (this.valid) {
        let codigos = this.selected.map((id) => {
          let item = this.list.find((item) => item.id == id);
          return item.id;
        });

        var params = {
          codes: codigos,
          measure: this.item.measure != null ? this.item.measure.id : null,
          presentation_type: this.item.presentation_type != null ? this.item.presentation_type.id : null,
          amount: this.item.amount
        };

        this.loading_imprimir = true;

        productoService.print(params, this.format_page).then((response) => {
          try {
            //Transformo a string la respuesta para poder enviar mensajes de error.
            let result = JSON.parse(this.arrayBufferToString(response.data));
            if (!result.status) {
              this.toast.error(result.msg);
            }
          } catch (e) {
            let blob;
            let link;
            switch (response.headers["content-type"]) {
              case "application/pdf":
              case "text/html; charset=UTF-8":
                blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                //link.download = code+'.pdf'
                link.click();
                break;
              case "application/zip":
                blob = new Blob([response.data], {
                  type: "application/zip",
                });
                link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                link.download = "Etiquetas.zip";
                //link.download = code+'.pdf'
                link.click();
                break;
              case "text/plain; charset=UTF-8":
                blob = new Blob([response.data], {
                  type: "text/plain",
                });
                link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                link.download = codigos[0] + ".txt";
                //link.download = code+'.pdf'
                link.click();
                break;
            }
          }
          this.loading_imprimir = false;
          this.item = this.defaultItem;
          this.$emit("close");
          this.$emit("cleanSelected");
        });
      }
    },
    arrayBufferToString(buffer) {
      var arr = new Uint8Array(buffer);
      var str = String.fromCharCode.apply(String, arr);
      if (/[\u0080-\uffff]/.test(str)) {
        throw new Error(
          "this string seems to contain (still encoded) multibytes"
        );
      }
      return str;
    },
  },
};
</script>
