<template>
  <div>
    <Title :pages="[{ icon: 'list-check', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <ModalView :show="dialog_view" @close="closeViewModal()" :task="mainItem" />

      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="filter()"
        :editItem="mainItem"
      />

      <PerformeTransferenciaModal v-if="update_access" :dialog="dialog_performe_guardado" @updateDialog="closeModals" @loadList="filter()" :task="mainItem" />

      <v-card class="mb-4" v-if="list_access">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_from" density="comfortable" type="date" name="date_from" label="Desde" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_to" density="comfortable" type="date" name="date_to" label="hasta" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.date_type"
                    name="date_type"
                    density="comfortable"
                    :items="date_types"
                    label="Tipo de Fecha"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.task_type"
                    density="comfortable"
                    name="task_type"
                    item-title="name"
                    item-value="id"
                    :items="tasks_types"
                    label="Tipo de tareas"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.id" density="comfortable" label="Codigo" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.state"
                    density="comfortable"
                    name="state"
                    item-title="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.assigned_user"
                    density="comfortable"
                    name="usuario"
                    item-title="user.name"
                    item-value="id"
                    :items="users"
                    label="Usuario"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.almacen"
                    density="comfortable"
                    name="almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-12 pt-0 pb-3" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.task_type.name }}</td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.user_create.name }}</td>
              <td>{{ item.scheduled_date }}</td>
              <td>{{ item.assigned_user != null ? item.assigned_user.name : "-" }}</td>
              <td>{{ item.assigned_date }}</td>
              <td>{{ item.start_date }}</td>
              <td>{{ item.prioridad }}</td>
              <td>{{ item.state.name }}</td>

              <td class="align-items-center justify-content-center d-flex w-auto">
                <v-tooltip bottom v-if="AllowModify(item)">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="green" class="mr-2" v-on:click="openPerforme(item)" size="small"> mdi-arrow-right-box</v-icon>
                  </template>
                  <span>Realizar</span>
                </v-tooltip>


                <v-tooltip bottom v-if="item.state.id == service.STATE_FINALIZADO">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewTask(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom v-if="AllowModify(item)">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="AllowModify(item)">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="openCancelDialog(item)" size="small">mdi-close </v-icon>
                  </template>
                  <span>Cancelar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />
      <DialogYesNo :title="'Cancelar tarea'" :message="'¿Esta seguro que desea cancelar la tarea?'" :open="dialog_cancel" @cancel="closeCancelDialog" @accept="cancelTask" :loading="loading_cancel" />
    </v-container>
  </div>
</template>

<script>
import { taskService } from "@/libs/ws/taskService";
import { employeeService } from "@/libs/ws/employeeService";
import { almacenService } from "@/libs/ws/almacenService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import PerformeTransferenciaModal from "./PerformeTransferenciaModal.vue";
import ModalView from "./ModalView.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";

export default {
  components: { Modal, PerformeTransferenciaModal, ModalView, DialogDelete, DialogYesNo },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      taskService: taskService,
      service: taskService,
      page_title: "Administración de Tareas",
      modal_title: "Tarea",
      asn: null,
      date_types: ["Fecha creacion", "Fecha planificada", "Fecha ejecucion", "Fecha finalizacion"],
      tasks_types: [],
      users: [],
      almacenes: [],
      states: [],
      mainItem: {
        id: null,
        task_type: null,
        creation_date: null,
        user_create: null,
        scheduled_date: null,
        assigned_user: null,
        assigned_date: null,
        start_date: null,
        end_date: null,
        prioridad: null,
        state: null,

        //Otros que dependen de task_type
      },
      headers: [
        {
          title: "Codigo",
          align: "left",
          sortable: true,
          key: "id",
        },
        {
          title: "Tipo",
          align: "left",
          sortable: true,
          key: "task_type.name",
        },
        {
          title: "Fecha de creacion",
          align: "left",
          sortable: true,
          key: "creation_date",
        },
        {
          title: "Creada por",
          align: "left",
          sortable: true,
          key: "user_create.username",
        },
        {
          title: "Fecha planificada",
          align: "left",
          sortable: true,
          key: "scheduled_date",
        },
        {
          title: "Asignada a",
          align: "left",
          sortable: true,
          key: "assigned_user.username",
        },
        {
          title: "Fecha de asignacion",
          align: "left",
          sortable: true,
          key: "assigned_date",
        },
        {
          title: "Fecha de ejecucion",
          align: "left",
          sortable: true,
          key: "start_date",
        },
        {
          title: "Prioridad",
          align: "left",
          sortable: true,
          key: "prioridad",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "state",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
      filters: {
        date_from: new Date().toISOString().substr(0, 10),
        date_to: new Date().toISOString().substr(0, 10),        
        date_type: null,
        task_type: null,
        state: null,
        assigned_user: null,
        almacen: null,
        id: "",
        external_reference: "",
      },

      dialog_performe_guardado: false,
      dialog_view: false,
      loading_cancel: false,
      dialog_delete: false,
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.filters.almacen = JSON.parse(localStorage.getItem("almacen"));

    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadUsers();
    this.loadTasksTypes();
    this.loadStates();
  },
  methods: {
    openPerforme(item) {
      if (!this.AllowModify(item)) {
        this.toast.error("La tarea se encuentra " + item.state.name);
        return;
      }
      this.mainItem = item;

      if (
        item.task_type.id == this.service.TASK_RECEPCION ||
        item.task_type.id == this.service.TASK_TRANSFERENCIA
      ) {
        this.dialog_performe_guardado = true;
        return;
      }

      this.toast.error("El realizar tarea no esta implementado.");
    },
    closeModals() {
      this.dialog_performe_guardado = false;
      this.dialog = false;

      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadUsers() {
      employeeService
        .filter({})
        .then((response) => {
          this.users = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTasksTypes() {
      taskService
        .list_types()
        .then((response) => {
          this.tasks_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadStates() {
      taskService
        .list_states()
        .then((response) => {
          this.states = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    viewTask(item) {
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.dialog_view = true;
    },
    closeViewModal() {
      this.dialog_view = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    openCancelDialog(item) {
      this.dialog_cancel = true;
      this.mainItem = item;
    },
    cancelTask() {
      if (this.loading_cancel) {
        return;
      }

      if (this.mainItem.id == null) {
        this.toast.error("No se ha seleccionado ninguna tarea");
        return;
      }

      if (!this.AllowModify(this.mainItem)) {
        this.toast.error("La tarea se encuentra " + this.mainItem.state.name);
        return;
      }

      this.loading_cancel = true;
      taskService
        .cancel({ id: this.mainItem.id })
        .then((response) => {
          if (response.status === true) {
            this.toast.success("Tarea cancelada");
            this.filter();
            this.closeCancelDialog();
          } else {
            this.toast.error(response.msg);
          }
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_cancel = false;
        });
    },
    closeCancelDialog() {
      this.dialog_cancel = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    AllowModify(item) {
      return ![taskService.STATE_FINALIZADO, taskService.STATE_CANCELADA, taskService.STATE_EN_PROCESO].includes(item.state.id);
    },
  },
};
</script>
