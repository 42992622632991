<template>
  <div>
    <Title :pages="[{ icon: 'arrow-right', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <!-- v-if="create_access" -->

      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_from" density="comfortable" type="date" name="date_from"
                    label="Desde" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_to" density="comfortable" type="date" name="date_to" label="hasta"
                    :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.almacen"
                    density="comfortable"
                    name="almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <!-- <div class="col-md-3">
                    <v-text-field v-model="filters.state" density="comfortable"
                      name="state" label="Estado" :disabled="disabled"></v-text-field>
                  </div> -->

                <div class="col-md-3">
                  <v-text-field v-model="filters.code" density="comfortable" name="code" label="Orden de envio"
                    :disabled="disabled"></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-text-field v-model="filters.id" density="comfortable" label="Numero de ola" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-9 pt-2" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card>
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar"
                density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..."
          class="expandable-rows">
          <template v-slot:item="{ item }">
            <tr @click="toggleExpand(item.id)">
              <td class="expand-icon">
                <v-icon>{{ expandedRows.includes(item.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </td>
              <td>{{ item.id }}</td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.almacen.name }}</td>

              <td>{{ item.status.name }}</td>
              <td>{{ item.description }}</td>
            </tr>
            <tr v-if="expandedRows.includes(item.id)">
              <td colspan="6" class="pl-0 pr-0">
                <v-data-table class="custom-subtable" :headers="shipmentHeaders" :items="item.shipment_orders" hide-default-footer
                  disable-pagination style="background-color: #ccc">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.code }}</td>
                      <td>{{ item.client.name }}</td>
                      <td>{{ item.almacen.name }}</td>
                      <td>{{ item.receiver }}</td>
                      <td>{{ item.address }}</td>
                      <td>{{ item.location }}</td>
                      <td>{{ item.status.name }}</td>
                      <td>{{ item.sub_status.name }}</td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)"
                              size="small">mdi-delete </v-icon>
                          </template>
                          <span>Eliminar de la ola</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

<DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteWave"
  :loading="loadingSubmit" />
</div>
</template>

<script>
import { waveService } from "@/libs/ws/waveService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import { shipmentOrderService } from "@/libs/ws/shipmentOrderService";
import { almacenService } from "@/libs/ws/almacenService";

export default {
  components: { DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: waveService,
      shipmentOrderService: shipmentOrderService,
      page_title: "Olas de preparacion",
      modal_title: "Ola de preparacion",
      list: [],
      expandedRows: [],
      headers: [
        {},
        {
          title: "Numero",
          align: "left",
          sortable: true,
          key: "id",
        },
        {
          title: "Fecha",
          align: "left",
          sortable: true,
          key: "creation_date",
        },

        {
          title: "Almacen",
          align: "left",
          sortable: true,
          key: "almacen.name",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "status",
        },
        {
          title: "Descripcion",
          align: "left",
          sortable: true,
          key: "description",
        },
      ],
      shipmentHeaders: [
        { title: "Codigo", key: "code", sortable: true, align: "left" },
        { title: "Cliente", key: "client.name", sortable: true, align: "left" },
        { title: "Almacen", key: "almacen.name", sortable: true, align: "left" },
        { title: "Receptor", key: "receiver", sortable: true, align: "left" },
        { title: "Direccion", key: "address", sortable: true, align: "left" },
        { title: "Localidad", key: "location", sortable: true, align: "left" },
        { title: "Estado", key: "status.name", sortable: true, align: "left" },
        { title: "Sub estado", key: "sub_status.name", sortable: true, align: "left" },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
      filters: {
        date_from: new Date().toISOString().substr(0, 10),
        date_to: new Date().toISOString().substr(0, 10),
        code: "",
        almacen: null,
        id: null,
      },
      almacenes: [],
    };
  },
  mounted() {
    this.filters.almacen = JSON.parse(localStorage.getItem("almacen"));

    this.loadList(this.service);
    this.loadAlmacenes();
  },
  methods: {
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    toggleExpand(id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter((rowId) => rowId !== id);
      } else {
        this.expandedRows.push(id);
      }
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    deleteWave() {
      this.loadingSubmit = true;
      this.shipmentOrderService
        .delete_wave({ shipment_order: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.toast.success(response.msg);
            this.loadList(this.service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((e) => {
          this.toast.error("Error exception");
        })
        .finally(() => {
          this.dialog_delete = false;
          this.loadingSubmit = false;
        });
    },
  },
};
</script>
<style scoped>
.expand-icon {
  width: 50px;
  text-align: center;
}

.sub-table {
  padding: 0;
}

.expandable-rows .v-data-table__wrapper {
  overflow: visible !important;
}
</style>