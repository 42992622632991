<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="closeDialog()" fullscreen
      transition="dialog-bottom-transition">
      <v-card>
        <v-form ref="form" v-on:submit.prevent="importar($event)">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-row align="center" justify="center" class="mb-4 mt-4">
              <v-col md="10" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                <a-steps :current="step">
                  <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
              </v-col>
            </v-row>

            <div class="steps-content">
              <!--step 0-->
              <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition">
                <v-col md="6" class="mt-4 pr-0 pl-0 " >
                  <v-form ref="form_step0" class="form-step0-excel">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>{{ steps[0].title }}</b>
                      </p>
                    </div>
                    <v-label>
                      Descargar la plantilla de importacion de envíos. 
                      <v-label @click="downloadExcel" class="text-download font-weight-bold ms-1 me-1">
                        Descargar 
                      </v-label>
                   
                    </v-label>   
                    
                    <v-file-input class="mt-3" accept=".xlsx" label="Seleccionar excel" v-model="excelFile"
                      hint="Solo admite archivos formato .xlsx (No admite formato .xls)" persistent-hint></v-file-input>
                  </v-form>
                  <v-btn class="float-right" color="primary" @click="readExcel"
                    :disabled="excelFile == null">Siguiente</v-btn>
                </v-col>
              </v-row>

              <!--step 2-->
              <v-row align="start" justify="center" v-if="step == 1" transition="fade-transition">
                <v-col md="10" class="mt-4 pr-0 pl-0">
                  <v-form ref="form_step1">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>{{ steps[1].title }}</b>
                      </p>
                    </div>
                    <v-progress-linear v-model="percent" color="blue-grey" style="height: 25px !important" class="mb-3 mt-3">
                      <strong>{{ Math.ceil(percent) || 0 }}%</strong>
                    </v-progress-linear>

                    <v-data-table :headers="headers" :items="list_bulk" :search="search" :loading="loading_table"
                      class="expandable-rows" :items-per-page="10" hide-default-footer dense>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="expand-icon" @click="toggleExpand(item.object.external_reference)">
                            <v-icon>
                              {{ expandedRows.includes(item.object.external_reference) ? 'mdi-chevron-up' :
                                'mdi-chevron-down' }}
                            </v-icon>
                          </td>
                          <td>{{ item.object.external_reference }}</td>
                          <td>{{ item.object.date }}</td>
                          <td>{{ item.object.estimated_departure_date }}</td>
                          <td>{{ item.object.document_type.name }}</td>
                          <td>{{ item.object.client.name }}</td>
                          <td>{{ item.object.receiver }}</td>
                          <td>{{ item.object.address + ", " + item.object.location }}</td>
                          <td style="min-width: 200px"
                            v-if="!item.loading && item.status == 1 && item.msg == '' && !item.status_create">
                            <i class="fa fa-check text-green"></i>
                          </td>
                          <td style="min-width: 200px"
                            v-if="!item.loading && item.status == 1 && item.msg != '' && !item.status_create">
                            <span class="text-red">{{ item.msg }}</span>
                          </td>
                          <td style="min-width: 200px" v-if="!item.loading && item.status == 1 && item.status_create">
                            <span class="text-green">Importado</span>
                          </td>
                          <td style="min-width: 200px" v-if="!item.loading && item.status == 0">
                            {{ "Pendiente de procesado" }}
                          </td>
                          <td style="min-width: 200px" v-if="item.loading">Procesando...</td>
                        </tr>
                        <tr v-if="expandedRows.includes(item.object.external_reference)">
                          <td colspan="12" class="pl-0 pr-0">
                            <v-data-table class="custom-subtable" :headers="headers_presentation"
                              :items="item.object.items_arr" hide-default-footer disable-pagination
                              style="background-color: #ccc">
                              <template v-slot:item="{ item }">
                                <tr>
                                  <td>{{ item.product.sku }}</td>
                                  <td>{{ item.amount }}</td>
                                  <td>{{ item.lote }}</td>
                                  <td>{{ item.serie }}</td>
                                  <td>{{ item.manufacture_date }}</td>
                                  <td>{{ item.expiration_date }}</td>
                                  <td>{{ item.external_line_number }}</td>
                                </tr>
                              </template>
                            </v-data-table>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-row>
                      <v-col cols="6" class="text-end">
                        <v-label class="msg-success">Importaciones creadas: {{ importaciones_creadas }}</v-label>

                      </v-col>
                      <v-col cols="6">
                        <v-label class="msg-error">Importaciones fallidas: {{ importaciones_no_creadas }}</v-label>
                      </v-col>
                    </v-row>


                    <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                    <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeDialog()">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :disabled="submit_disabled" :loading="loading" v-if="!disabled && step == 1">{{ formBtn
              }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { clientService } from "@/libs/ws/clientService";

import ValidMixin from "@/mixins/ValidMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import readXlsxFile from "read-excel-file";


export default {
  components: {},
  mixins: [ValidMixin,ExportMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      step: 0,
      headers: [
        {},
        {
          title: "Ref. Externa",
          align: "left",
          sortable: true,
          key: "external_reference",
        },
        {
          title: "Fecha",
          align: "left",
          sortable: true,
          key: "date",
        },
        {
          title: "Fecha estimada de entrega",
          align: "left",
          sortable: true,
          key: "estimated_departure_date",
        },

        {
          title: "Tipo de Documento",
          align: "left",
          sortable: true,
          key: "document_type.name",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "client.name",
          visible_for_client: false,
        },
        {
          title: "Destinatario",
          align: "left",
          sortable: true,
          key: "receiver",
        },
        {
          title: "Direccion",
          align: "left",
          sortable: true,
          key: "",
        },
        {
          title: "Resultado",
          align: "left",
          sortable: false,
          key: "msg"
        },
      ],
      headers_presentation: [
        { title: "Producto", key: "product.sku", sortable: false },
        { title: "Cantidad", key: "amount", sortable: false },
        { title: "Lote", key: "lote", sortable: false },
        { title: "Serie", key: "serie", sortable: false },
        { title: "Fecha de Manufactura", key: "manufacture_date", sortable: false },
        { title: "Fecha de Expiracion", key: "expiration_date", sortable: false },
        { title: "Numero de linea externo", key: "external_line_number", sortable: false },
      ],
      steps: [
        {
          title: "Importar Excel",
          description: "Importe el Excel",
          content: "First-content",
        },
        {
          title: "Detalle del Excel",
          description: "Detalle del Excel",
          content: "Second-content",
        },
      ],
      excelFile: null,
      list_bulk: [],
      expandedRows: [],
      loading_create: false,
      importaciones_creadas: 0,
      importaciones_no_creadas: 0,
      total_importaciones: 0,
      submit_disabled: false,
      progress: 0,
      loading_table: false,
    };
  },
  watch: {
    
  },
  mounted() {
  },

  computed: {
    percent: {
      get() {
        return ((this.getProcesadosValidos() + this.getProcesadosNoValidos()) * 100) / this.list_bulk.length;
      },
      set(value) {
        return value;
      },
    },
  },

  methods: {
    downloadExcel() {
      this.exportExcel("/storage/plantillas/plantilla_shipment_orders.xlsx", {}, "Plantilla_Ordenes_Envio.xlsx")
    },
    getProcesadosValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && element.status_create).length;
    },
    getProcesadosNoValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && !element.status_create).length;
    },
 
    toggleExpand(id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter((rowId) => rowId !== id);
      } else {
        this.expandedRows.push(id);
      }
    },
    async readExcel() {
      this.step++;
      this.list_bulk = [];
      this.loading_table = true;
      try {
        const rowsSheet1 = await readXlsxFile(this.excelFile, { sheet: 1 });
        const rowsSheet2 = await readXlsxFile(this.excelFile, { sheet: 2 });

        for (const element of rowsSheet1.slice(1)) {
          const obj = {
            object: await this.parseObject(element, rowsSheet2), // Asegúrate de que parseObject devuelva un objeto completo
            msg: "",
            status: 0,
            status_create: false,
            loading: false,
          };
          this.list_bulk.push(obj);
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        this.loading_table = false;
        
      }
    },
    async parseObject(element, rowsSheet2) {
      const object = Object.assign({}, this.mainItem);

      object.external_reference = element[0].toString();
      object.date = formatUtils.formatDate(element[1]);
      object.estimated_departure_date = formatUtils.formatDate(element[2]);
      object.document_type = { id: element[4], name: element[3] }; 
      object.almacen = { id: element[5] };
      object.client = { id: element[6], name: "" }; 
      object.sub_client = { id: element[7] };
      object.receiver = element[8];
      object.address = element[9];
      object.location = element[10];
      object.postal_code = element[11].toString();
      object.provincia = { id: element[13] , name: element[12]};

      object.items_arr = [];
      rowsSheet2.forEach(row => {
        if (row[0] === element[0]) {
          object.items_arr.push({
            product: { sku: row[1] },
            amount: row[2],
            lote: row[3],
            serie: row[4],
            manufacture_date: row[5],
            expiration_date: row[6],
            external_line_number: row[7],
          });
        }
      });

      try {
        const clientResponse = await clientService.get({ id: element[5] });
        object.client = clientResponse.result || "Cliente no encontrado";
      } catch (error) {
        console.error(`Error al obtener el cliente con ID ${element[5]}:`, error);
        object.client = "Error al obtener el cliente";
      }

      this.total_importaciones = object.items_arr.length;
    
      return object;
    },

    closeDialog() {
      this.$emit("updateDialog", false);
      this.step = 0;
      this.list_bulk = [];
      this.excelFile = null;
    },
    prev() {
      this.step--;
    },

    async importar() {
      var index = 0;
      this.loading_create = true;
      this.submit_disabled = true;

      for (const element of this.list_bulk) {
        if (this.list_bulk[index].msg == "" && !this.list_bulk[index].status_create && this.list_bulk[index].status == 0) {
          this.list_bulk[index].loading = true;
          let result = await this.createItem(this.list_bulk[index].object);

          this.list_bulk[index].loading = false;
          this.list_bulk[index].status = 1;
          this.list_bulk[index].status_create = result.status;
          this.list_bulk[index].msg = result.msg;

          if (result.status) {
            this.importaciones_creadas++;
          } else {
            this.importaciones_no_creadas++;
          }

        }

        index++;
      }

      this.loading_create = false;

      if(this.importaciones_creadas === this.list_bulk.length) {
        this.$emit('updateDialog',false);
        this.step = 0;
        this.list_bulk = [];
        this.excelFile = null;
        this.$emit('loadList');
      }

    },
    async createItem(item) {
      if (item.amount < 0) {
        item.amount = item.amount * -1;
      }
      return this.service.create(item);
    },
  },
};
</script>
