import { wsService } from "./wsService";

const section = "waves";


function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get(param = { enable: true }) {
  return wsService.make_post_request(section + "/get", param);
}


export const waveService = {
  section,
  filter,
  remove,
  create,
  update,
  get,
  close,
};
