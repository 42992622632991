<template>
  <div>
    <Title :pages="[{ icon: 'box', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :editItem="mainItem"
      />

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.sku }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.client.name }}</td>
              <td>{{ item.category != null ? item.category.name : "-" }}</td>
              <td>{{ item.subcategory != null ? item.subcategory.name : "-" }}</td>
              <td>{{ item.product_type != null ? item.product_type.name : "-" }}</td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="purple" class="mr-2" v-on:click="print(item)" size="small">
                      mdi-printer</v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2" v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <ModalPrint :show="dialog_print" @close="dialog_print = false" :selected="selected" :list="list"
        @cleanSelected="selected = []" :presentations="presentations"/>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />
    </v-container>
  </div>
</template>

<script>
import { productoService } from "@/libs/ws/productoService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import ModalPrint from "./ModalPrint.vue";

export default {
  components: { Modal,ModalPrint },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: productoService,
      page_title: "Productos",
      modal_title: "Producto",
      dialog_print: false,
      selected: [],
      presentations: [],
      headers: [
        {
          title: "SKU",
          align: "left",
          sortable: true,
          key: "sku",
        },
        {
          title: "Nombre",
          align: "left",
          sortable: true,
          key: "name",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "client.name",
        },
        {
          title: "Rubro",
          align: "left",
          sortable: true,
          key: "category.name",
        },
        {
          title: "Subrubro",
          align: "left",
          sortable: true,
          key: "subcategory.name",
        },
        {
          title: "Tipo de Producto",
          align: "left",
          sortable: true,
          key: "product_type.name",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
    };
  },
  mounted() {
    this.loadList(this.service);
  },
  methods: {
    print(item) {
      this.selected = [];
      this.selected.push(item.id);
      this.presentations = item.presentations;
      this.dialog_print = true;
    },
  },
};
</script>
