import { wsService } from "./wsService";

const section = "shipment-orders";

const STATUS_PENDIENTE_PREPARACION = 'PEN';
const STATUS_STOCK_RESERVADO = 'STR';
const STATUS_EN_PREPARACION = 'EPR';
const STATUS_PREPARADO = 'PRE';
const STATUS_EMPAQUETADO = 'EMP';
const STATUS_DESPACHADO = 'DES';

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function list_status() {
  return wsService.make_get_request(section + "/list_status");
}

function get(param = { enable: true }) {
  return wsService.make_post_request(section + "/get", param);
}

function generatePickingDetail(param = {}) {
  return wsService.make_post_request(section + "/reserve", param);
}

function create_wave(param = {}) {
  return wsService.make_post_request(section + "/create-wave", param);
}
function assign_wave(param = {}) {
  return wsService.make_post_request(section + "/assign-wave", param);
}

function assign_dock(param = {}) {
  return wsService.make_post_request(section + "/assign-dock", param);
}

function delete_wave(param = {}) {
  return wsService.make_post_request(section + "/delete-wave", param);
}

function cancel_reserve(param = {}) {
  return wsService.make_post_request(section + "/cancel-reserve", param);
}

export const shipmentOrderService = {
  STATUS_PENDIENTE_PREPARACION,
  STATUS_STOCK_RESERVADO,
  STATUS_EN_PREPARACION,
  STATUS_PREPARADO,
  STATUS_EMPAQUETADO,
  STATUS_DESPACHADO,  

  section,
  filter,
  remove,
  create,
  update,
  get,
  close,
  list_status,
  generatePickingDetail,
  create_wave,
  assign_wave,
  delete_wave,
  cancel_reserve,
  assign_dock
};
