<template>
  <v-layout>
    <v-dialog :modelValue="dialog" max-width="1200px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name
          }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-row align="center" justify="center" class="mb-4 mt-4">
              <v-col md="11" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                <a-steps :current="step">
                  <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                </a-steps>
              </v-col>
            </v-row>

            <div class="steps-content">
              <!--step 0-->
              <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition">
                <v-col md="11" class="mt-4 pr-0 pl-0">
                  <v-form ref="form_step0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0"><b>Datos Generales</b></p>
                    </div>
                    <p>Complete los datos generales de la tarea</p>
                    <v-row>
                      <v-col md="6">
                        <v-text-field type="date" v-model="item.scheduled_date" name="scheduled_date"
                          label="Fecha planificada*" :disabled="disabled" :rules="[rules.required]"></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-combobox v-model="item.task_type" name="task_type" item-title="name" item-value="id"
                          :items="tasks_types" label="Tipo de tarea*" autocomplete="off"
                          :disabled="disabled || item.id > 0" :rules="[rules.requiredComboBox]" clearable></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="6">
                        <v-combobox v-model="item.almacen" name="rol" item-title="name" item-value="id"
                          :items="almacenes" label="Almacen*" autocomplete="off" :disabled="disabled || item.id > 0"
                          :rules="[rules.requiredComboBox]" clearable></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox v-model="item.assigned_user" name="assigned_user" item-title="name" item-value="id"
                          :items="users" label="Usuario asignado" autocomplete="off" :disabled="disabled"
                          clearable></v-combobox>
                      </v-col>
                      <v-col md="6">
                        <v-combobox :rules="[rules.required]" v-model="item.prioridad" name="prioridad*"
                          :items="priorities" label="Prioridad" autocomplete="off" :disabled="disabled"
                          clearable></v-combobox>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-btn class="float-right btn-second" v-if="step < steps.length - 1"
                    @click="nextStep0">Siguiente</v-btn>
                </v-col>
              </v-row>

              <!--step 1-->
              <v-form ref="form_step1">
                <v-row align="start" justify="center" v-if="step == 1" transition="fade-transition">
                  <v-col md="11" class="mt-4 pr-0 pl-0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>Selecione el tipo de movimiento a realizar</b>
                      </p>
                    </div>

                    <MovimienteAsk :form_ref="this.$refs.form_step1" :task="item"
                      v-if="item.task_type.id == taskService.TASK_TRANSFERENCIA" @setStep="setStep"
                      @addPropertyToTask="addPropertyToTask" :disabled="disabled" />

                    <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <!--step 2-->
              <v-form ref="form_step2">
                <v-row align="start" justify="center" v-if="step == 2" transition="fade-transition">
                  <v-col md="11" class="mt-4 pr-0 pl-0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>Origen de tarea de {{ item.task_type.name }}</b>
                      </p>
                    </div>

                    <TaskReposicionTransferenciaOrigen :form_ref="this.$refs.form_step1"
                      v-if="item.task_type.id == taskService.TASK_TRANSFERENCIA" :task="item" :filters="filters"
                      @setStep="setStep" @addPropertyToTask="addPropertyToTask"
                      @addPropertyToFilters="addPropertyToFilters" :disabled="disabled" />

                    <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-form ref="form_step3">
                <v-row align="start" justify="center" v-if="step == 3" transition="fade-transition">
                  <v-col md="11" class="mt-4 pr-0 pl-0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>Origen de tarea de {{ item.task_type.name }}</b>
                      </p>
                    </div>

                    <TaskReposicionTransferenciaDetalleOrigen :form_ref="this.$refs.form_step1"
                      v-if="item.task_type.id == taskService.TASK_TRANSFERENCIA" @setStep="setStep" :disabled="disabled"
                      :task="item" @addPropertyToTask="addPropertyToTask" />

                    <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <!--step 3-->
              <v-form ref="form_step4">
                <v-row align="start" justify="center" v-if="step == 4" transition="fade-transition">
                  <v-col md="11" class="mt-4 pr-0 pl-0">
                    <div class="px-2 py-1 mb-3 bg-subtitle-section">
                      <p class="text-subtitle-1 mb-0">
                        <b>Destino de tarea de {{ item.task_type.name }}</b>
                      </p>
                    </div>

                    <TaskReposicionTransferenciaDestino :form_ref="this.$refs.form_step2"
                      v-if="item.task_type.id == taskService.TASK_TRANSFERENCIA" :task="item" :filters="filters"
                      @setStep="setStep" @addPropertyToTask="addPropertyToTask"
                      @addPropertyToFilters="addPropertyToFilters" :disabled="disabled" />

                    <v-btn v-if="step > 0" @click="prev" class="mt-5" color="blue-grey">Atras</v-btn>
                    <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1"
                      @click="next">Siguiente</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeModal()">Cerrar</v-btn>

            <v-menu v-if="!disabled && step == 4">
              <template v-slot:activator="{ props }">
                <v-btn color="success" v-bind="props" :loading="loading">
                  {{ formBtn }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title class="cursor" :loading="loading" @click="createItem()">{{ formBtn
                    }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="cursor" :loading="loading" @click="createAndDo()">{{ formBtn }} y
                    Realizar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { almacenService } from "@/libs/ws/almacenService";
import { employeeService } from "@/libs/ws/employeeService";
import { documentsTypeService } from "@/libs/ws/documentsTypeService";
import { taskService } from "@/libs/ws/taskService";
import ValidMixin from "@/mixins/ValidMixin.vue";
import ConstantEmployeesType from "@/constants/employee_types";

import TaskReposicionTransferenciaOrigen from "./TaskReposicionTransferenciaOrigen.vue";
import TaskReposicionTransferenciaDestino from "./TaskReposicionTransferenciaDestino.vue";
import TaskReposicionTransferenciaDetalleOrigen from "./TaskReposicionTransferenciaDetalleOrigen.vue";

import MovimienteAsk from "./MovimienteAsk.vue";

export default {
  components: { TaskReposicionTransferenciaOrigen, TaskReposicionTransferenciaDestino, MovimienteAsk, TaskReposicionTransferenciaDetalleOrigen },
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      taskService: taskService,
      almacenes: [],
      users: [],
      tasks_types: [],
      priorities: ["Baja", "Media", "Alta"],
      loading: false,
      step: 0,
      steps: [
        {
          title: "Datos Generales",
          content: "First-content",
        },
        {
          title: "Tipo de Transferencia",
          content: "Second-content",
        },
        {
          title: "Origen",
          content: "Thrird-content",
        },
        {
          title: "Detalle de origen",
          content: "Fourth-content",
        },
        {
          title: "Destino",
          content: "Fifth-content",
        },
      ],

      //Filters
      filters: {
        origen: {
          code: null,
          product: null,
          zona: null,
          ubicacion: null,
        },
      },
      defaultFilters: null,

      //TaskModel
      item: {
        id: "",
        almacen: null,
        task_type: { id: 0, name: "" },
        assigned_user: null,
        scheduled_date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        prioridad: "Media",
        observations: "",
        // detalle depende de task_type
      },
      defaultItem: null,
      listDetalleOrigen: []
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadComboBox();
        this.disabled = this.formBtn == "";
        this.step = 0;
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.defaultFilters = JSON.parse(JSON.stringify(this.filters));
  },
  methods: {
    closeModal() {
      this.$emit("updateDialog", false);
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.filters = JSON.parse(JSON.stringify(this.defaultFilters));
    },
    setStep(step) {
      this.step = step;
    },
    addPropertyToTask(key, value) {
      this.item[key] = value;
    },
    addPropertyToFilters(key, value) {
      this.filters[key] = value;
    },
    prev() {
      this.step--;
    },
    async nextStep0() {
      const { valid } = await this.$refs.form_step0.validate();
      if (valid) {
        this.step++;
      }
    },
    comboCategoryChange(event) {
      this.loadSubcategorias();
    },
    loadComboBox() {
      this.loadUsers();
      this.loadAlmacenes();
      this.loadDocumentTypes();
      taskService.list_types({ 'internal': false }).then((response) => {
        this.tasks_types = response.result.list;
      });
    },
    loadDocumentTypes() {
      documentsTypeService
        .filter({ enable: true })
        .then((response) => {
          this.documents_types = response.result.list;
        })
        .catch((e) => {
          console.log("documentsTypeService.filter error");
          console.log(e);
        });
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          console.log("almacen", response);
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadUsers() {
      employeeService
        .filter_users({ employee_type: ConstantEmployeesType.TYPE_OPERATOR })
        .then((response) => {
          this.users = response.result.list;
        })
        .catch((e) => {
          console.log("operariosService.filter error");
          console.log(e);
        });
    },
    addNewLPN(item, index = -1) {
      if (index == -1) this.item.lpns.push(item);
      else this.item.lpns[index] = item;
    },
    removeProduct(index) {
      this.item.lpns.splice(index, 1);
    },
    prepareDataPost(data) {
      if (data.task_type.id == taskService.TASK_TRANSFERENCIA) {

        data.origin_ubicacion_code = data.origin_ubicacion.code;
        data.origin_lpn_code = data.origin_lpn.code;
        data.destination_ubicacion_code = data.destination_ubicacion.code;
        data.destination_lpn_code = data.destination_lpn.code;


        if (data.do_task) {
          data.create_and_performe = true;
        }
        data.items_arr = [];
        const series_arr = [];

        data.assigned_locations.forEach((location) => {
          if (location.transfer_quantity > 0 && location.presentation_atributes.serie != null) {
            series_arr.push({ serie: location.presentation_atributes.serie });
          }
        });

        const item = {
          product: { id: data.assigned_locations[0].presentation_atributes.product_presentation.product.id },
          amount: data.assigned_locations[0].transfer_quantity,
          manufacture_date: data.assigned_locations[0].presentation_atributes.manufacture_date,
          lote: data.assigned_locations[0].presentation_atributes.lote,
          expiration_date: data.assigned_locations[0].presentation_atributes.expiration_date,
          series_arr: series_arr.length > 0 ? series_arr : null
        }

        data.items_arr.push(item);

        return data;
      } else {
        return data;
      }
    },
    async createItem() {
      if (this.loading) return;
      var data = JSON.parse(JSON.stringify(this.item));
      data = this.prepareDataPost(data);
      console.log("data", data);

      //Validate every form
      //*los demas forms son null
      const { valid_4 } = await this.$refs.form_step4.validate();
      console.log("valid_4", valid_4);
      const x = true;
      if (x) {
        this.loading = true;

        this.service
          .create(data)
          .then((response) => {
            if (response.status) {
              this.toast.success("El " + this.modal_title + " se creo correctamente");
              this.closeModal();
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((e) => {
            this.toast.error(e);
          })
          .finally(() => {
            this.loading = false;
          });

      } else {
        this.toast.error("Complete los campos obligatorios, revise los pasos previos.");
      }
    },
    createAndDo() {
      if (this.item.task_type.id == taskService.TASK_TRANSFERENCIA) {
        if (this.item.destination_ubicacion == null) {
          this.toast.error("Debe seleccionar una ubicacion de destino");
          return;
        }

        if (this.item.destination_lpn == null) {
          this.toast.error("Debe seleccionar un LPN de destino");
          return;
        }
      }

      this.item.do_task = true;
      this.createItem();
    },
  },
};
</script>
