<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field label="Nombre*" name="name" v-model="item.user.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-text-field label="Username*" name="username" v-model="item.user.username" :disabled="disabled || item.id > 0" :rules="[rules.required]" />

                <v-text-field
                  :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="show = !show"
                  :type="show ? 'text' : 'password'"
                  label="Password*"
                  name="password"
                  v-model="item.user.password"
                  :disabled="disabled"
                  :rules="item.id > 0 ? [rules.notRequired] : [rules.required]"
                  :hint="item.id > 0 ? 'Si desea modificar la contraseña complete este campo, caso contrario deje el campo vacio.' : ''"
                />

                <v-combobox
                  v-model="item.user.role"
                  name="rol"
                  item-title="name"
                  item-value="id"
                  :items="roles"
                  label="Rol*"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBox]"
                  clearable
                ></v-combobox>

                <v-combobox
                    v-model="item.almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen*"
                    autocomplete="off"
                    :disabled="disabled"
                    :rules="[rules.requiredComboBox]"
                    clearable
                  ></v-combobox>

                <div class="row">
                  <div class="col-md-12">
                    <v-checkbox label="Habilitado" :disabled="disabled" v-model="item.user.enable"></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ConstantEmployeesType from "@/constants/employee_types";
import { rolService } from "@/libs/ws/rolService";
import { almacenService } from "@/libs/ws/almacenService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      roles: [],
      almacenes: [],
      loading: false,
      name: "",
      item: {
        id: "",
        name: "",
        almacen: null,        
        user: {
          username: "",
          password: "",
          role: null,
          enable: true,
        },
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.loadRoles();
     this.loadAlmacenes();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadRoles() {
      rolService
        .getOperators()
        .then((response) => {
          this.roles = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = {
          id: this.item.id,
          name: this.item.user.name,
          username: this.item.user.username,          
          role: this.item.user.role.id,
          employee_type: ConstantEmployeesType.TYPE_OPERATOR,
          enable: this.item.user.enable ? 1 : 0,
          almacen: this.item.almacen,
        };

        if (this.item.user.password != undefined && this.item.user.password != "") {
          data["password"] = this.item.user.password;          
        }

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
